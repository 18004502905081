import React, { useState, Fragment } from 'react';
import './App.css';

//TODO:
//MARKUP
//box/container  ✅
//input + button ✅
//list of items ✅
//button -> trigger randomizer ✅

//FUNCTIONALITIES
//input -> save to statate ✅
// button -> add to list ✅
// button -> trigger randomize

const defaultOptions = [
  { text: 'Option 1', selected: false },
  { text: 'Option 2', selected: false },
  { text: 'Option 3', selected: false },
  { text: 'Option 4', selected: true }
];

function App() {
  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    if (inputValue.trim().length === 0) return;

    let newTempItems = [];
    if (inputValue.includes(',')) {
      newTempItems = inputValue.split(',').map(item => {
        return {
          text: item.trim(),
          selected: false
        };
      });
    } else {
      newTempItems = [
        {
          text: inputValue,
          selected: false
        }
      ];
    }

    setInputValue('');
    const newItems = [...items, ...newTempItems];
    setItems(newItems);
  };

  const randomizer = () => {
    for (let i = 0; i <= 20; i++) {
      setTimeout(pickRandomItem, 100 * i);
    }
  };

  const pickRandomItem = () => {
    const randomItem = items[Math.floor(Math.random() * items.length)];

    const newItems = items.map(item =>
      randomItem === item ? { ...item, selected: true } : { ...item, selected: false }
    );

    setItems(newItems);
  };

  const handleItemRemove = idx => {
    const newItems = items.filter((item, index) => index !== idx);
    setItems(newItems);
  };

  return (
    <Fragment>
      <h1 className="text-4xl mb-3">Randomizer</h1>
      <div className="container mx-auto shadow-xl w-fill sm:w-6/12 p-6 bg-white">
        <form onSubmit={handleSubmit} className="flex">
          <input
            className="py-2 px-2 border border-gray-500 flex-1"
            type="text"
            placeholder="Add a new Item"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-500"
          >
            Add
          </button>
        </form>

        <ul>
          {items.map((item, idx) => (
            <li
              key={idx}
              onClick={() => handleItemRemove(idx)}
              className={`${
                item.selected ? 'bg-blue-200 rounded' : ''
              } select-none my-3 px-2 py-1 rounded flex cursor-pointer hover:line-through hover:text-red-500`}
            >
              {item.text}
            </li>
          ))}
        </ul>

        {items.length > 1 && (
          <button
            className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4 rounded"
            onClick={randomizer}
          >
            Randomize
          </button>
        )}
      </div>
    </Fragment>
  );
}

export default App;
